import React from 'react';
import Form from './Form';
import CreateWrapper from '../../Components/Form/CreateWrapper';

const Create = () => {
  return (
    <CreateWrapper>
      <Form />
    </CreateWrapper>
  );
};

export default Create;