import React from 'react';
import CustomList from '../../Components/List/CustomList';
import { FunctionField, TextField } from 'react-admin';
import HeaderList from '../../Components/HeaderList/HeaderList';
import { Box, Typography } from '@mui/material';

const List = () => {
  return (
    <CustomList
      datagrid={{
        rowClick: 'edit'
      }}
      paramsList={{
        perPage: 50,
        sort: { field: 'date_created', order: 'ASC' },
        actions: <HeaderList hasSearch hasCreate />,
      }}
    >
      <TextField label={'Nombre'} source={'name'} />
      <TextField label={'Tipo'} source={'type'} />
      <FunctionField
        source='price'
        label='Precio'
        render={(record) => (
          <Box display={'flex'} gap={'3px'} alignItems={'baseline'}>
            <Typography>${record.price}</Typography>
            <Typography fontSize={'12px'} color={'gray'}>{record.currency.toLowerCase()}</Typography>
          </Box>
        )}
      />
    </CustomList>
  );
};

export default List;
