import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  ListBase,
  Pagination,
  useListContext,
  List,
} from 'react-admin';
import Lottie from 'react-lottie';
import emptyList from '../../assets/animations/empty.json';
// import notificationBell from '../../assets/audio/notification.mp4';

const width = '100%';
const CustomEmpty = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80%',
      }}
    >
      <Typography sx={{ fontSize: '18px' }}>
        No se encontraron resultados.
      </Typography>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: emptyList,
        }}
        height={200}
        width={200}
      />
    </Box>
  );
};
const CustomList = ({
  children,
  paramsList,
  styleDatagrid,
  styleListBase,
  datagrid,
  liveUpdates = false,
  playSound = false,
  rowClick,
}) => {
  return (
    <List
      {...paramsList}
      sx={{
        maxWidth: width,
        '& .RaList-content': {
          backgroundColor: '#FFF',
          margin: '0px 20px',
        },
        ...styleListBase,
      }}
      empty={false}
    >
      <CustomDataGrid
        styleDatagrid={styleDatagrid}
        datagrid={datagrid}
        liveUpdates={liveUpdates}
        playSound={playSound}
        rowClick={rowClick}
      >
        {children}
      </CustomDataGrid>
    </List>
  );
};

const CustomDataGrid = ({
  children,
  styleDatagrid,
  liveUpdates,
  playSound = false,
  datagrid,
  rowClick,
}) => {
  const { refetch, total, filterValues, isFetching, isLoading } =
    useListContext();
  const [totalAux, setTotalAux] = useState(null);

  useEffect(() => {
    let timeId = null;
    if (liveUpdates) {
      timeId = setInterval(() => refetch(), 5000);
    }
    return () => clearInterval(timeId);
  }, []);

  if (playSound) {
    useEffect(() => {
      if (!isLoading) {
        setTotalAux(total);
      }
    }, [isLoading]);

    useEffect(() => {
      try {
        if (
          !isLoading &&
          totalAux &&
          total > totalAux &&
          Object.keys(filterValues).length === 0
        ) {
          setTotalAux(total);
          // esta re fuerte esto.
          // soundBell.play();
        }
      } catch {
        console.log('Error document');
      }
    }, [total, totalAux]);
  }

  return (
    <Datagrid
      size={'small'}
      bulkActionButtons={false}
      empty={<CustomEmpty />}
      {...datagrid}
      rowClick={rowClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        '& .RaDatagrid-tableWrapper': {
          borderRadius: '.25em',
          boxShadow: '0 .07em .125em 0 rgba(0,0,0,.15)',
          backgroundColor: '#FFF',
          width: width,
        },
        '& .RaDatagrid-headerCell': {
          zIndex: 0,
          backgroundColor: 'transparent',
        },
        '& .RaDatagrid-rowCell': {
          borderBottom: '.0625em solid #eee',
        },
        '& .RaDatagrid-row': {
          height: '51px',
        },
        '& .MuiTable-root': {
          border: '1px solid #E8E8E8',
          borderRadius: '10px 10px 0px 0px',
        },
        '& .MuiTableCell-body': {
          borderBottom: '1px solid #E8E8E8',
        },
        '& .MuiTableCell-head': {
          backgroundColor: '#F5F5F5',
        },
        '& .MuiTableSortLabel-root': {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '19px',
          color: '#7C7C7C',
          textTransform: 'uppercase',
        },
        ...styleDatagrid,
      }}
    >
      {children}
    </Datagrid>
  );
};

export default CustomList;
