import React, { useRef, useState } from 'react';
import styles from './HeaderList.module.css';
import CreateButton from '../Button/CreateButton';
import { SearchComponent } from '../Filter/Search';
import { Box, Button, Popover } from '@mui/material';
import { useResourceContext } from 'react-admin';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CsvDownloader } from '../Csv/CsvDownloader';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterList from '../Filter/FilterList';

const titleResource = {
  fontSize: '36px',
  fontWeight: 700,
  lineHeigh: '50px',
  margin: 0,
  padding: 0,
  color: '#000000',
  textTransform: 'capitalize',
  marginTop: '10px',
};
const HeaderList = ({ hasSearch, hasCreate, csv, hasExport, ...props }) => {
  const resourceBase = useResourceContext();
  const resource = () => {
    switch (resourceBase) {
      case 'payment_requests':
        return 'Movimientos';
      case 'merchants':
        return 'Comercios';
      case 'admins':
        return 'Usuarios';
      case 'products':
        return 'Productos';
      default:
        return resourceBase;
    }
  };

  return (
    <div className={styles.container}>
      <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingX: '20px',
            marginBottom: 2,
          }}
        >
          <p style={titleResource}>{resource()}</p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              justifyContent: 'space-between',
            }}
          >
            {hasSearch && (
              <SearchComponent
                sx={{ width: '350px', borderRadius: '10px' }}
                placeholder={'Buscar'}
              />
            )}
            <div
              style={{
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                height: '50px',
                gap: 20,
                marginLeft: 50,
              }}
            >
              {
                hasCreate && (
                  <CreateButton />
                )
              }

              {/* <OptionDots csv={csv}/> */}
              {hasExport && <CsvDownloader />}
            </div>
            <div style={{ width: '200px', visibility: 'hidden' }}></div>
          </div>
          <FilterList {...props} />
        </Box>
      </div>

    </div>
  );
};

export const OptionDots = ({ from, csv, filterValues }) => {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div ref={buttonRef}>
        <Button
          variant='text'
          sx={{ backgroundColor: '#F5F5F5', color: '#7C7C7C', padding: '5px 10px', borderRadius: '10px', height: '45px', border: '1px solid #E8E8E8' }}
          onClick={() => setOpen(true)}
        >
          <MoreVertIcon />
        </Button>
      </div>
      <Popover
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '10px',
            marginTop: '10px',
            padding: '2px 0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {csv && <CsvDownloader from={from} filterValues={filterValues} />}
      </Popover>
    </>
  );
};
export const FilterWrapper = ({ setShowFilters, showFilters }) => {
  return (
    <>
      <div>
        <Button
          variant='text'
          sx={{ backgroundColor: '#F5F5F5', color: '#7C7C7C', padding: '20px', borderRadius: '10px', height: '45px', border: '1px solid #E8E8E8' }}
          onClick={() => setShowFilters(!showFilters)}
        >
          <p
            style={{
              color: '#7C7C7C',
              textTransform: 'none',
              fontSize: '14px',
              lineHeight: '22px',
              marginRight: '5px',
              fontWeight: 600,
            }}
          >
            Filtros
          </p>
          {showFilters ? (
            <ExpandLessIcon size={20} color="grey" />
          ) : (
            <ExpandMoreIcon size={20} color="grey" />
          )}
        </Button>
      </div>
    </>
  );
};

export default HeaderList;