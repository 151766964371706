import React from 'react';
import Card from '../../Components/Card/Card';
import { BooleanInput, NumberInput, SelectInput, TextInput } from 'react-admin';
import { Box } from '@mui/material';

const CURRENCIES_MERCHANT = [
  {
    id: 'ARS',
    name: 'ARS'
  },
  {
    id: 'USD',
    name: 'USD'
  }
];

const Form = () => {
  return (
    <>
      <Card
        title={'Datos del producto'}
        border
        sx={{ display: 'flex', flexDirection: 'column' }}
        sxTitle={{ fontSize: '20px', lineHeight: '27px', fontWeight: 600, color: '#000000' }}
        sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
      >

        <Box display={'flex'} width={'100%'} gap={9}>
          <TextInput required source={'name'} fullWidth label={'Nombre'} />
          <TextInput required source={'type'} fullWidth label={'Tipo de producto'} />
        </Box>

        <Box display={'flex'} width={'100%'} gap={9}>
          <NumberInput required fullWidth source='price' label='Precio' />
          <NumberInput fullWidth source='max_items' label='Cantidad maxima de productos por orden' />
        </Box>

        <TextInput source={'description'} fullWidth label={'Descripcion'} />

        <Box display={'flex'} width={'100%'} gap={9}>
          <SelectInput required defaultValue={'ARS'} source={'currency'} label={'Moneda'} choices={CURRENCIES_MERCHANT} />
          <BooleanInput label={'Habilitado'} source={'enabled'} defaultValue={true} />
          <BooleanInput label={'Requiere email'} source={'require_email'} defaultValue={false} />
        </Box>

      </Card>
    </>
  );
};

export default Form;