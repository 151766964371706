export const USD_CURRENCY = {
  id: '123',
  enabled: true,
  name: 'Stripe',
  ticker: 'USD',
  logo: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_usdcard.png',
  chain_id: -2,
  chain_name: 'Stripe'
};

export const BTC = { 
  id: 1,
  name: 'Bitcoin', 
  coin: 'BTC',
  isEVM: false,
  blockchain: 'Lightning Network',
  logo: require('../Images/logo_btc.png'), 
  blockchain_logo: require('../Images/logo_ln.png'), 
  color: '#E8860F',
  address: '0xAHDghj1i27sdhik18sdhjaoqhdkjhdyq2',
  hasAddress: false
};

export const USDT = { 
  id: 2,
  name: 'USDT',
  coin: 'USDT',
  isEVM: true, 
  blockchain: 'Polygon',
  logo: require('../Images/logo_usdt.png'),
  blockchain_logo: require('../Images/logo_polygon.png'),
  color: '#26a17b',
  hasAddress: true
};

export const UXD = { 
  id: 3,
  name: 'UXD',
  isEVM: true, 
  coin: 'UXD',
  blockchain: 'LaChain',
  logo: require('../Images/logo_uxd.png'), 
  blockchain_logo: require('../Images/logo_lachain.png'),
  color: '#0ab582',
  hasAddress: true
};

export const BCH = { 
  id: 4,
  name: 'Bitcoin Cash',
  coin: 'BCH',
  isEVM: false, 
  blockchain: null, 
  logo: require('../Images/logo_bch.png'), 
  blockchain_logo: null, 
  color: '#078b66',
  hasAddress: true
};

export const DOC = { 
  id: 5,
  name: 'DoC',
  coin: 'DOC',
  isEVM: true,
  blockchain: 'Rootstock',
  logo: require('../Images/logo_doc.png'), 
  blockchain_logo: require('../Images/logo_rootstock.png'),
  color: '#1c814c',
  hasAddress: true
};

export const USDT_SEPOLIA = { 
  id: 6,
  name: 'USDT',
  coin: 'USDT',
  isEVM: true, 
  blockchain: 'Sepolia',
  logo: require('../Images/logo_usdt.png'),
  blockchain_logo: null,
  color: '#26a17b',
  hasAddress: true
};

export const DOC_SEPOLIA = { 
  id: 7,
  name: 'DoC',
  coin: 'DOC',
  isEVM: true,
  blockchain: 'Sepolia',
  logo: require('../Images/logo_doc.png'), 
  blockchain_logo: null,
  color: '#1c814c',
  hasAddress: true
};

export const WETH = { 
  id: 8,
  name: 'WETH',
  coin: 'WETH',
  isEVM: true,
  blockchain: 'Ethereum',
  logo: require('../Images/logo_weth.png'), 
  blockchain_logo: require('../Images/logo_eth.png'),
  color: '#1c814c',
  hasAddress: true
};

export const USDC = { 
  id: 9,
  name: 'USDC',
  coin: 'USDC',
  isEVM: true,
  blockchain: 'Optimism',
  logo: require('../Images/logo_usdc.png'), 
  blockchain_logo: require('../Images/logo_op.png'),
  color: '#1c814c',
  hasAddress: true
};


export const ETH = { 
  id: 10,
  name: 'Ethereum',
  coin: 'ETH',
  isEVM: true,
  blockchain: 'Ethereum',
  logo: require('../Images/logo_eth.png'), 
  blockchain_logo: require('../Images/logo_eth.png'),
  color: '#1c814c',
  hasAddress: true
};

export const MORFI = { 
  id: 11,
  name: 'MORFI',
  coin: 'MORFI',
  isEVM: true, 
  blockchain: 'LaChain',
  logo: require('../Images/logo_morfi.png'), 
  blockchain_logo: require('../Images/logo_lachain.png'),
  color: '#1c814c',
  hasAddress: false
};

export const TOKENS = {
  'btc': BTC,
  'usdt': USDT,
  'uxd': UXD,
  'bch': BCH,
  'doc': DOC,
  'weth': WETH,
  'usdc': USDC,
  'eth': ETH,
  'morfi': MORFI,
};