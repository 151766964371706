import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from 'ra-language-spanish';

const customSpanishMessages = {
  ...spanishMessages,
  ra: {
    ...spanishMessages.ra,
    action: {
      ...spanishMessages.ra.action,
      confirm: 'Aceptar'
    },
    message: {
      ...spanishMessages.ra.message,
      invalid_form: 'El formulario no es válido. Por favor, revise los errores.', // Custom message
    },
    validation: {
      ...spanishMessages.ra.validation,
      required: 'Este campo es obligatorio.',
      minLength: 'Debe tener al menos %{min} caracteres.',
      maxLength: 'Debe tener como máximo %{max} caracteres.',
      minValue: 'Debe ser al menos %{min}.',
      maxValue: 'Debe ser como máximo %{max}.',
      number: 'Debe ser un número.',
      email: 'Debe ser un correo electrónico válido.',
      // Add more custom validation messages or overrides here
    },
    navigation: {
      ...spanishMessages.ra.navigation,
      page_rows_per_page: 'Filas por página'
    }
  },
};

const i18nProvider = polyglotI18nProvider(() => customSpanishMessages, 'es');

export default i18nProvider;
