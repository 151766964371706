import React from 'react';
import CustomList from '../../Components/List/CustomList';
import {
  FunctionField,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { Box } from '@mui/material';
import HeaderList from '../../Components/HeaderList/HeaderList';
import { TokenFieldList } from '../../Components/Field/TokenField';
import { useCurrencies } from '../../utils/helpers/useCurrencies';

const List = () => {
  const { currencies } = useCurrencies();

  return (
    <CustomList
      rowClick={'edit'}
      styleDatagrid={{
        '& .column-name': {
          width: '40%',
        },
        '& .column-transactions': {
          textAlign: 'center',
          width: '30%',
        },
        '& .column-google_maps': {
          textAlign: 'center',
          width: '15%',
        },
        '& .column-direction': {
          textAlign: 'center',
          width: '15%',
        },
      }}
      paramsList={{
        perPage: 50,
        sort: { field: 'date_created', order: 'ASC' },
        actions: <HeaderList hasSearch hasCreate />,
      }}
    >
      <TextField source='name' label={'Nombre'} />
      <FunctionField
        source='transactions'
        label={'Coin'}
        render={(record) => (
          <TokenFieldList
            record={record}
            currencies={currencies}
            isMerchantList={true}
          />
        )}
      />
      <UrlField
        source='google_maps_link'
        label='Google Maps URL'
        target='_blank'
      />
      <TextField source='direction' label='Dirección' />

    </CustomList>
  );
};

export default List;
