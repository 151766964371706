import { Box, Button, Checkbox, IconButton, Popover, Typography } from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { theme } from '../../Theme';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const newFilterStyleBt = {
  backgroundColor: '#E8E8E8',
  padding: '10px',
  borderRadius: '5px',
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  textTransform: 'none',
};
const NewFilter = (props) => {
  const {
    _callback,
    label,
    singleSelect,
    setFilters,
    filterValues,
    optionValue,
    source,
    erase,
    date
  } = props;
  const [open, setOpen] = useState(false);
  // const [filterState, setFilterState] = useState(filterValues?.[source] ?? []);
  const refb = useRef();

  const handleOpen = () => {
    if(erase) {
      setFilters({});
    } else {
      setOpen(!open);
    }
  };

  return (
    <div ref={refb}>
      <Button 
        sx={{...newFilterStyleBt,color: erase ? '#FFF' : 'black',
          fontWeight: 600, 
          backgroundColor: erase ? 'red' : '#E8E8E8', 
          border: filterValues[source]?.length > 0 || Boolean(date && filterValues?.date)  ? `2px solid ${theme.palette.primary.main}` :
            null,
          '&:hover': {
            color: erase ? 'red' : 'black'
          }}}
        onClick={handleOpen}
      >{label}
      </Button>
      <FilterPopOver {...props} 
      // filterState={filterState} 
      // handlerFilterState={handlerFilterState} 
        open={open} setOpen={setOpen} refb={refb} />
    </div>
  );
};

export const FilterPopOver = (props) => {
  const { refb, setOpen, setFilters, filterValues, filter, optionValue, open, children, options, date, source, singleSelect } = props;

  const handleSelect = (e,v) => {
    
    if (!singleSelect) {
      if (!filterValues?.[source]?.includes(v)) {
        setFilters({...filterValues, [source]: filterValues?.[source] ? [...filterValues[source], v] : [v]});
      } else {
        setFilters({...filterValues, [source]: filterValues[source].filter((item) => item !== v)});
      }
    } else {
      if (filterValues?.[source] !== v) {
        setFilters({...filterValues, [source]: v});
      } else {
        setFilters({...filterValues, [source]: ''});
      }
    }
  };

  const MultiSelectOptions = ({ options, optionValue = 'id', optionLabel, ...rest }) => (
    <ul
      style={{
        listStyle: 'none',
        margin: 0,
        padding: '10px 15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {options?.map((option, index) => (
        <li
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            onClick={(e) => handleSelect(e, option[optionValue])}
            sx={{
              padding: 0,
              marginRight: 1,
            }}
            
            defaultChecked={rest.singleSelect ? rest.filterValues?.[source] === option[optionValue] : rest.filterValues?.[source]?.includes(option[optionValue])}
          />
          <span
            style={{
              color: 'black',
              fontWeight: 600,
            }}
          >
            {option?.[optionLabel] ?? option.label ?? option}
          </span>
        </li>
      ))}
    </ul>
  );

  return (
    <Popover
      anchorEl={refb.current}
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPopover-paper': {
          borderRadius: '10px',
          marginTop: '5px',
          maxHeight: '300px',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {options && (
        <MultiSelectOptions {...props} /> 
      )}
      { date && (
        // <DateFilter {...props} />
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px', gap:'5px'}}>
          <Typography sx={{
            fontSize: '0.8em',
            fontWeight: '600',
          
          }}>Desde</Typography>
          <DateInput 
            sx={{width: 'auto',}}
            value={dayjs(filterValues?.date)} 
            onChange={(e)=> {
              setFilters({ ...props.filterValues, date: dayjs(e).format('YYYY-MM-DD HH:MM:ss.SSSSSS')});
            }}
          />
        </Box>
      )}
    </Popover>
  );
};

export const DateInput = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker {...props} />
    </LocalizationProvider>
  );
};

export default memo(NewFilter);
