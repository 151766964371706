import React from 'react';
import { EditBase } from 'react-admin';
import SimpleFormCustom from '../../Components/Form/SimpleFormCustom';
import Form from './Form';

const Edit = () => {
  return (
    <EditBase mutationMode='optimistic'>
      <SimpleFormCustom
        sx={{ display: 'flex', flexDirection: 'column' }}
        hasDelete
        messageDelete={{
          confirmContent: '¿Estas seguro de eliminar este producto?',
          confirmTitle: 'Eliminar producto'
        }}
      >
        <Form />
      </SimpleFormCustom>
    </EditBase>
  );
};

export default Edit;